<template>
    <div class="page-container">
        <div class="login-left">
            <img src="../../assets/login-left.png" alt="">
        </div>
        <div class="login-right">
            <div class="login-form">
                <div class="login-title"><img src="../../assets/login-txt.png" alt=""></div>
                <input type="text" placeholder="请输入手机号" class="input-phone"/>
                <div class="vcode-box">
                    <input type="text" placeholder="请输入验证码" class="input-vcode"/>
                    <div class="btn-send">发送验证码</div>
                </div>
                <div class="btn-login">立即登录</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created(){
        
    }
}
</script>

<style lang="scss" src="./index.scss" scoped>

</style>